<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA SERVICIOS
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i class="fas fa-cog fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaServicios.length}}</span>
                            <br>
                            <span class="text-muted h6">Servicios</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de servicios </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalNuevoServicio = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo servicio
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Servicios registrados</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i> </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table :items="listaServicios" :fields="campoServicios" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template #cell(coste)="data">
                                        S/. {{ data.value}}
                                    </template>
                                    <template #cell(descripcion)="data">
                                        {{ data.value | truncate(60)}}
                                    </template>
                                    <template #cell(acciones)="param">
                                        <b-button @click="abrirModalModificarServicio(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-button>
                                        <b-button @click="eliminarServicio(param)" class="mr-1  mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>

                        <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalNuevoServicio">
                            <template #header>
                                <h6 class="modal-title">
                                    <i class="fas fa-plus-square fa-md"></i>
                                    <span class="h5"> Nuevo Servicio </span>
                                </h6>
                                <CButtonClose @click="modalNuevoServicio = false" class="text-white" />
                            </template>

                            <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(registrarServicio)">
                                    <b-row>
                                        <b-col md="12">
                                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Nombre:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosServicio.descripcion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="Código identificador" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Código identificador:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el código" v-model="datosServicio.identificador"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="coste" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Coste (S/.):" class="mb-2">
                                                    <b-form-input type="number"  step=".01" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el coste" v-model="datosServicio.coste"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button size="md" variant="danger" @click="modalNuevoServicio = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>

                        <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalModificarServicio">
                            <template #header>
                                <h6 class="modal-title">
                                    <i class="fas fa-edit fa-md"></i>
                                    <span class="h5"> Actualizar servicio </span>
                                </h6>
                                <CButtonClose @click="modalModificarServicio = false" class="text-white" />
                            </template>
                            <validation-observer ref="observer3" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(modificarServicio)">
                                    <b-row>
                                        <b-col md="12">
                                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Nombre:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosModificarServicio.descripcion"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="Código identificador" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Código identificador:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el código" v-model="datosModificarServicio.identificador"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="coste" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Coste (S/.):" class="mb-2">
                                                    <b-form-input type="number"  step=".01" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el coste" v-model="datosModificarServicio.coste"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button size="md" variant="danger" @click="modalModificarServicio = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import throttle from 'lodash/throttle'

import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            modalNuevoServicio: false,
            modalModificarServicio: false,
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoServicios: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "descripcion",
                    label: "Nombre",
                    class: "text-center",
                },
                 {
                    key: "identificador",
                    label: "Código",
                    class: "text-center",
                },
                {
                    key: "coste",
                    label: "Costo",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaServicios: [],
            datosServicio: {
                idServicio: '',
                identificador: '',
                descripcion: '',
                coste: 0
            },
            datosModificarServicio: {
                idServicio: '',
                identificador: '',
                descripcion: '',
                coste: 0
            },
            disabled: false,
        }
    },
    methods: {
      
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        registrarServicio() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-servicio",
                    me.datosServicio, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalNuevoServicio = false;
                    me.listarServicios();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        modificarServicio() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-servicio",
                    me.datosModificarServicio, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalModificarServicio = false
                    me.listarServicios();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetmodalNuevoServicio() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosServicio.nombres = '',
            this.datosServicio.identificador = '',
                this.datosServicio.importeComision = 0
        },
        resetModalModificarServicio() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
        },
        abrirModalModificarServicio(param) {
            let me = this;
            me.datosModificarServicio.idServicio = param.item.idServicio;
            me.datosModificarServicio.identificador = param.item.identificador;
            me.datosModificarServicio.descripcion = param.item.descripcion;
            me.datosModificarServicio.coste = param.item.coste;
            me.modalModificarServicio = true;

        },
        listarServicios() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-servicios")
                .then(function (response) {
                    me.listaServicios = response.data;
                    me.totalRows = me.listaServicios.length;
                    //me.obtenerDatosEstadisticos();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarServicio(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el servicio?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "master/eliminar-servicio", {
                            idServicio: param.item.idServicio,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarServicios();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalNuevoServicio: function (val) {
            if (val == false) {
                this.resetmodalNuevoServicio();
            }
        },
        modalModificarServicio: function (val) {
            if (val == false) {
                this.resetModalModificarServicio();
            }
        },
     
    },
    mounted() {
        let me = this;
        me.listarServicios();
    }

}
</script>
